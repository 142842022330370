import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './vuex/index';
import axios from './service/axios';
import dayjs from "dayjs"
import ElementUI from 'element-ui';
import './service/common';
import 'element-ui/lib/theme-chalk/index.css';
import './style/theme/index.css';
import './css/common.scss';
import './assets/icon/iconfont.css'
Vue.config.productionTip = false
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.prototype.axios = axios;
Vue.prototype.dayjs = dayjs;
Vue.use(ElementUI, {  size: 'small' })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 全局指令实现el-input输入框手机号控制
//组件中使用<el-input v-number-only maxlength="11" placeholder="请输入手机号码" @keyup.enter.native="handleQuery" />
Vue.directive('numberOnly', {
  bind: function(el) {
    el.handler = function() {
      el.childNodes[1].value = el.childNodes[1].value.replace(/\D+/g, '');
    }
    el.addEventListener('keyup', el.handler);
  },
  unbind: function(el) {
    el.removeEventListener('keyup', el.handler);
  }
})