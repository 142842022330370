const storage = {
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove(key) {
        localStorage.removeItem(key);
    },
    get(key) {
        let result = '';
        let value = localStorage.getItem(key);
        if (value) {
            try {
                result = JSON.parse(value);
            } catch (error) {
                result = '';
            }
        }
        return result;
    }
};

export default storage;
