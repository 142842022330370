import Vue from 'vue';
import Vuex from 'vuex';
import storageUtil from '../utils/storage';
import {
  GET_SEND_VERIFY_CODE_TIME,
  GET_USERINFO_AND_TOKEN_FROM_STORAGE
} from './action-types';
import consult from './modules/consult';
import service from './modules/service';
import shopService from './modules/shopService';


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    consult,
    service,
    shopService
  },
  strict: debug
});

// 页面初始化或刷新时从 sessionStorage 中读取用户数据
const exposedToken = storageUtil.get('exposedToken');
if (exposedToken) {
  store.dispatch(GET_USERINFO_AND_TOKEN_FROM_STORAGE, {
    userInfo: storageUtil.get('userInfo'),
    exposedToken: storageUtil.get('exposedToken'),
    headerToken: storageUtil.get('headerToken')
  });
}
const lastSendVerifyCodeTimestamp = storageUtil.get(
  'lastSendVerifyCodeTimestamp'
);
if (lastSendVerifyCodeTimestamp) {
  store.dispatch(GET_SEND_VERIFY_CODE_TIME, {
    sendTime: parseInt(lastSendVerifyCodeTimestamp)
  });
}

export default store;
