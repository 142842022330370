//医生反馈
const state = {
  latestMsg: {}, //最新一条的消息
  imLogin: false, //是否登录了环信
  historyList: [], //历史记录
  error:{}
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  //处理环信发来的新消息
  setMsg(state, msg) {
    let matchStr = 'doctor_customer'
    let doc_con_username = msg.to.match(matchStr) ? msg.from : msg.to
    let count = sessionStorage.getItem('group' + doc_con_username)
    if (!count) {
      count = 1
    } else {
      count++
    }
    sessionStorage.setItem('group' + doc_con_username, count, 1)
    state.latestMsg = msg
    //如果有保存的历史记录，插入对应的，没有的话不用保存  进入页面会调用环信API
    //系统消息不保存
    let index = state.historyList.findIndex(ele => {
      return ele.doc_con_username == doc_con_username
    });
    if (index != -1) {
      state.historyList[index].msgs.push(msg)
    }
  },
  //新建 覆盖历史记录
  setHistoryList(state, {
    msgs,
    doc_con_username
  }) {
    let obj = {
      doc_con_username,
      msgs
    }
    let index = state.historyList.findIndex(ele => {
      return ele.doc_con_username == doc_con_username
    })
    if (index == -1) {
      state.historyList.push(obj)
    } else {
      state.historyList[index] = obj
    }

  },
  addNewMsg(state, {
    msg
  }) {
    let index = state.historyList.findIndex(ele => {
      return ele.doc_con_username == msg.to
    });
    if (index != -1) {
      state.historyList[index].msgs.push(msg)
    }
  },
  setError(state, msg){
    state.error = msg
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};