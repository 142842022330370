import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/pages/login/Login.vue"),
  },
  {
    path: "/logincode",
    component: () => import("@/pages/login/LoginCode.vue"),
  },
  {
    path: "/home",
    name: "主页",
    component: () => import("@/pages/home/Home.vue"),
    children: [
      {
        path: "/home/workbenchNew",
        name: "工作台",
        component: () => import("@/pages/home/WorkbenchNew.vue"),
      },
      {
        path: "/person",
        name: "个人中心",
        component: () => import("@/pages/person/Person.vue"),
        children: [
          {
            path: "/person/personInfo",
            name: "个人中心 / 个人信息",
            component: () => import("@/pages/person/PersonInfo.vue"),
          },
        ],
      },
      {
        path: "/system",
        name: "店铺管理",
        component: () => import("@/pages/systemset/System.vue"),
        children: [
          {
            path: "/system/user",
            name: "店铺管理 / 成员管理",
            component: () => import("@/pages/systemset/user/UsersList"),
          },
          {
            path: "/system/role",
            name: "店铺管理 / 岗位管理",
            component: () => import("@/pages/systemset/role/RoleList"),
          },
          {
            path: "/system/setrole",
            name: "店铺管理 / 成员权限配置",
            component: () => import("@/pages/systemset/role/SetRole"),
          },
          {
            path: "/system/selfRaising",
            name: "店铺管理 / 地址管理",
            component: () =>
              import("@/pages/systemset/selfRaising/SelfRaising"),
          },
          {
            path: "/system/freeShipping",
            name: "店铺管理 / 包邮设置",
            component: () => import("@/pages/systemset/freight/FreeShipping"),
          },
          {
            path: "/system/freightList",
            name: "店铺管理 / 模板列表",
            component: () => import("@/pages/systemset/freight/FreightList"),
          },
          {
            path: "/system/addFreight",
            name: "店铺管理 / 设置模板",
            component: () => import("@/pages/systemset/freight/AddFreight"),
          },
          {
            path: "/system/storeSettings",
            name: "店铺管理 / 店铺设置",
            component: () => import("@/pages/systemset/storeSettings"),
          },
        ],
      },
      {
        path: "/goods",
        name: "商品管理",
        component: () => import("@/pages/goods/Goods.vue"),
        children: [
          {
            path: "/goods/GoodsList",
            name: "商品管理 / 商品列表",
            component: () => import("@/pages/goods/GoodsList"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/goods/AddGood",
            name: "商品管理 / 添加商品",
            component: () => import("@/pages/goods/AddGood"),
          },
          {
            path: "/goods/editGood",
            name: "商品管理 / 编辑商品",
            component: () => import("@/pages/goods/EditGood"),
          },
          {
            path: "/goods/checkGood",
            name: "商品管理 / 查看商品",
            component: () => import("@/pages/goods/checkGood"),
          },
          {
            path: "/goods/GoodsOverview",
            name: "商品管理 / 商品概况",
            component: () => import("@/pages/goods/GoodsOverview"),
          },
          {
            path: "/goods/Evaluation",
            name: "商品管理 / 商品评价",
            component: () => import("@/pages/goods/Evaluation"),
          },
        ],
      },
      {
        path: "/discounts",
        name: "优惠券",
        component: () => import("@/pages/discounts/Discounts.vue"),
        children: [
          {
            path: "/discounts/discountsList",
            name: "售后管理 / 优惠券管理",
            component: () => import("@/pages/discounts/DiscountsList"),
            meta: {
              // keepAlive:true
            },
          },
          {
            path: "/discounts/addDiscount",
            name: "售后管理 / 添加优惠券",
            component: () => import("@/pages/discounts/AddDiscount"),
          },
          {
            path: "/discounts/editDiscount",
            name: "售后管理 / 编辑优惠券",
            component: () => import("@/pages/discounts/EditDiscount"),
          },
          {
            path: "/discounts/giveDiscount",
            name: "售后管理 / 发放优惠券",
            component: () => import("@/pages/discounts/GiveDiscount"),
          },
          {
            path: "/discounts/checkDetails",
            name: "售后管理 / 查看优惠券",
            component: () => import("@/pages/discounts/checkDetails"),
          },
        ],
      },
      {
        path: "/order",
        name: "订单管理",
        component: () => import("@/pages/order/Order.vue"),
        children: [
          {
            path: "/order/OrderList",
            name: "订单管理 / 订单列表",
            component: () => import("@/pages/order/OrderList"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/order/orderDetail",
            name: "订单管理 / 订单详情",
            component: () => import("@/pages/order/OrderDetail"),
          },
          {
            path: "/order/GroupData",
            name: "订单管理 / 拼团数据",
            component: () => import("@/pages/order/GroupData"),
          },
          {
            path: "/order/GroupDetails",
            name: "订单管理 / 拼团数据",
            component: () => import("@/pages/order/GroupDetails"),
          },
        ],
      },
      {
        path: "/afterSales",
        name: "售后管理",
        component: () => import("@/pages/afterSales/AfterSales.vue"),
        children: [
          {
            path: "/afterSales/afterSalesList",
            name: "售后管理 / 售后列表",
            component: () => import("@/pages/afterSales/AfterSalesList"),
            meta: {
              // keepAlive: true,
            },
          },
          {
            path: "/afterSales/AfterSalesDetails",
            name: "售后管理 / 售后详情",
            component: () => import("@/pages/afterSales/AfterSalesDetails"),
            meta: {
              // keepAlive: true,
            },
          },
        ],
      },
      {
        path: "/user",
        name: "用户管理",
        component: () => import("@/pages/user/User.vue"),
        children: [
          {
            path: "/user/userList",
            name: "用户管理 / 用户列表",
            component: () => import("@/pages/user/UserList"),
          },
          {
            path: "/user/ListDetails",
            name: "用户管理 / 用户详情",
            component: () => import("@/pages/user/ListDetails"),
          },
        ],
      },
      {
        path: "/operation",
        name: "运营管理",
        component: () => import("@/pages/operation/Operation.vue"),
        children: [
          {
            path: "/operation/issueStamps/ruleList",
            name: "运营管理 / 发放券规则管理",
            component: () => import("@/pages/operation/issueStamps/RuleList"),
            meta: {
              // keepAlive:true
            },
          },
          {
            path: "/operation/issueStamps/addRule",
            name: "运营管理 / 添加规则",
            component: () => import("@/pages/operation/issueStamps/AddRule"),
          },
          {
            path: "/operation/issueStamps/editRule",
            name: "运营管理 / 编辑规则",
            component: () => import("@/pages/operation/issueStamps/EditRule"),
          },
          /* 活动管理 */
          {
            path: "/operation/activityMgmt/activityList",
            name: "运营管理 / 活动管理",
            component: () => import("@/pages/operation/activityMgmt/index"),
          },

          {
            path: "/operation/activityMgmt/addOrEditor",
            name: "运营管理 / 新增活动",
            component: () =>
              import("@/pages/operation/activityMgmt/addOrEditor"),
          },
          {
            path: "/operation/activityMgmt/addOrCheck",
            name: "运营管理 / 查看活动",
            component: () =>
              import("@/pages/operation/activityMgmt/addOrCheck"),
          },
          {
            path: "/operation/teamworkManagement/teamworkManagementList",
            name: "运营管理 / 拼团管理",
            component: () =>
              import(
                "@/pages/operation/teamworkManagement/teamworkManagementList"
              ),
          },
          {
            path: "/operation/teamworkManagement/addTeamworkManagement",
            name: "运营管理 / 新增拼团",
            component: () =>
              import(
                "@/pages/operation/teamworkManagement/addTeamworkManagement"
              ),
          },
          {
            path: "/operation/teamworkManagement/checkManagement",
            name: "运营管理 / 拼团查看",
            component: () =>
              import("@/pages/operation/teamworkManagement/checkManagement"),
          },
          /* 活动管理 */
          {
            path: "/operation/shopService/ShopService",
            name: "客服管理 / 客服咨询",
            component: () =>
              import("@/pages/operation/shopService/ShopService"),
          },
          /* 促销管理 */
          {
            path: "/operation/operation/promotion",
            name: "运营管理 / 促销管理",
            component: () => import("@/pages/operation/promotion/index"),
          },
          /* 促销管理 */
          {
            path: "/operation/operation/promotionDetails",
            name: "运营管理 / 促销设置",
            component: () =>
              import("@/pages/operation/promotion/promotionDetails"),
          },
        ],
      },
      {
        path: "/",
        name: "分销中心",
        component: () => import("@/pages/market/Market.vue"),
        children: [
          {
            path: "/market/marketList",
            name: "分销管理 / 推广商品管理",
            component: () => import("@/pages/market/MarketList"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "/market/temList",
            name: "分销管理 / 提成模板",
            component: () => import("@/pages/market/TemList"),
            meta: {
              keepAlive: true,
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
